import React from "react"

import Layout from "../components/layout"
import PrivacyLayout from "../components/privacy"

const PrivacyPage = () => (
  <Layout>
    <PrivacyLayout />
  </Layout>
)

export default PrivacyPage
