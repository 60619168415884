import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

const Container = styled.div`
  text-align: center;
`

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 78vh;
`

const Description = styled.p`
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`

const NameHeader = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 0;
`

const PrivacyLayout = () => (
  <StaticQuery
    query={graphql`
      query PrivacyQuery {
        site {
          siteMetadata {
            privacy
          }
        }
      }
    `}
    render={data => (
      <OuterContainer>
        <Container>
          {/* <Description>{data.site.siteMetadata.privacy}</Description> */}
          <h1>Privacy Policy</h1>
          <p>
            We only collect and store your Google Play Games id in our servers. That is only used to associate your profile with the game save data. None of the game save data or profile information is shared with other parties and are not used for anything other than the main function of the game.
          </p>
          <p>
            If you have any questions, please reach out at hamparawa@gmail.com
          </p>
        </Container>
      </OuterContainer>
    )}
  />
)

NameHeader.propTypes = {
  siteTitle: PropTypes.string,
  subtitle: PropTypes.string,
}

NameHeader.defaultProps = {
  siteTitle: ``,
  subtitle: ``,
}

export default PrivacyLayout
